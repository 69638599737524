import FsLightbox from "fslightbox-react";
import React, { useState, useCallback, memo } from "react";
import * as Icon from "react-feather";
import Image from "../components/Image";

const Portfolio = memo(
  ({ content: { title, subtitle, imageUrl, largeImageUrl, url } }) => {
    const [toggler, setToggler] = useState(false);

    const handleToggler = useCallback(() => {
      setToggler((prev) => !prev);
    }, []);

    return (
      <div className="mi-portfolio mi-portfolio-visible">
        <div className="mi-portfolio-image">
          <Image
            src={imageUrl || "/images/portfolio-image-placeholder.png"}
            alt={title}
            height="280px"
          />
          <ul>
            {largeImageUrl && (
              <li>
                <button onClick={handleToggler}>
                  <Icon.ZoomIn />
                </button>
              </li>
            )}
            {url && (
              <li>
                <a rel="noopener noreferrer" target="_blank" href={url}>
                  <Icon.Link />
                </a>
              </li>
            )}
          </ul>
        </div>
        <h5>
          {url ? (
            <a rel="noopener noreferrer" target="_blank" href={url}>
              {title}
            </a>
          ) : (
            title
          )}
        </h5>
        {subtitle && <h6>{subtitle}</h6>}
        {largeImageUrl && (
          <FsLightbox toggler={toggler} sources={largeImageUrl} />
        )}
      </div>
    );
  }
);

export default Portfolio;
