import React, { useMemo } from "react";
import useProgressiveImage from "@ohs/use-progressive-image";
import SkeletonLoader from "../components/SkeletonLoader";

const Image = ({ lqip, src, avif, alt, width = "100%", height = "auto" }) => {
  const sources = useMemo(
    () => [
      {
        srcSet: avif,
        type: "image/avif",
      },
    ],
    [avif]
  );

  const [loading] = useProgressiveImage({ img: src, sources });

  return (
    <div // Wrapper div to enforce full parent height and width
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: width,
        height: height,
      }}
      aria-busy={loading}
      aria-label={alt || "Image loading"}
    >
      <picture
        style={{
          width: "100%",
          height: "100%",
          display: "block",
        }}
      >
        {!loading && <source {...sources[0]} />}
        {loading ? (
          <SkeletonLoader
            count={1}
            height={height}
            width={width}
            aria-hidden="true"
          />
        ) : (
          <img
            src={src}
            alt={alt || "Decorative image"}
            height={height}
            width={width}
            style={{
              width: width,
              height: height,
              objectFit: "cover",
            }}
            loading="lazy"
          />
        )}
      </picture>
    </div>
  );
};

export default Image;
