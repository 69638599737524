import Mock from "../mock";
import { getAge, getExperienceTime } from "../../lib/utils";

const workExperience = [
  {
    id: 5,
    year: "2013 - 2015",
    position: "Frontend Developer",
    company: "Auburn Creative",
    start: 2013,
    end: 2015,
    skills: {
      AngularJS: 0,
      HTML: 50,
      CSS: 60,
      JavaScript: 50,
      TypeScript: 0,
      React: 0,
      GraphQL: 0,
      NodeJS: 0,
      DesignSystems: 0,
      Vue: 0,
      Wordpress: 80,
    },
    details:
      "Creating WordPress themes, plugins & widgets. Providing personalised client training to organisations as well as large international companies. Evaluating client requirements & translating into long-term solutions to work across all platforms & browsers. Creating development standards & kits for external contractors. Interviewing contractors & analysing their coding abilities. Determining accurate project costing & build timescales.",
  },
  {
    id: 4,
    year: "2015 - 2017",
    position: "Web Developer",
    company: "Metia",
    start: 2015,
    end: 2017,
    skills: {
      AngularJS: 70,
      HTML: 70,
      CSS: 70,
      JavaScript: 70,
      TypeScript: 20,
      React: 60,
      GraphQL: 40,
      NodeJS: 50,
      DesignSystems: 0,
      Vue: 0,
      Wordpress: 70,
    },
    details:
      "Developing .NET and Angular JS websites for high-end clients such as Dell & Microsoft. Working closely with designers, informing them on new technologies and UX trends.",
  },
  {
    id: 3,
    year: "2017 - 2019",
    position: "Frontend Engineer",
    company: "Profile Pensions",
    start: 2017,
    end: 2019,
    skills: {
      AngularJS: 60,
      HTML: 70,
      CSS: 70,
      JavaScript: 70,
      TypeScript: 20,
      React: 60,
      GraphQL: 40,
      NodeJS: 50,
      DesignSystems: 0,
      Vue: 0,
      Wordpress: 60,
    },
    details:
      "Developing and maintaining internal web apps using React, Redux and Node working closely with backend developers. Building progressive web apps to improve the user experience for our customers.",
  },
  {
    id: 2,
    year: "2019 - 2020",
    position: "Software Engineer",
    company: "Bravissimo",
    start: 2019,
    end: 2020,
    skills: {
      AngularJS: 30,
      HTML: 90,
      CSS: 90,
      JavaScript: 90,
      TypeScript: 80,
      React: 90,
      GraphQL: 70,
      NodeJS: 50,
      DesignSystems: 80,
      Vue: 0,
      Wordpress: 50,
    },
    details:
      "Maintaining a full-stack website (React, Redux, Node, SQL) application and internal tools, being on hand to provide help to junior developers. Providing learnings and reports from previous sprints and bringing new technologies, including researching and building MVPs.",
  },
  {
    id: 1,
    year: "2020 - 2023",
    position: "Senior JavaScript Engineer",
    company: "ManyPets",
    start: 2020,
    end: 2023,
    skills: {
      AngularJS: 30,
      HTML: 90,
      CSS: 90,
      JavaScript: 90,
      TypeScript: 80,
      React: 90,
      GraphQL: 70,
      NodeJS: 50,
      DesignSystems: 80,
      Vue: 60,
      Wordpress: 40,
    },
    details:
      "Working in a data-driven environment across multiple squads maintaining and developing progressive web apps and a patterns library. Involved in multiple councils with responsibilities such as accessibility, front-end foundations and SEO. Running sprint ceremonies and reporting on DORA metrics.",
  },
  {
    id: 0,
    year: "2023 - Present",
    position: "Lead Frontend Engineer/Full Stack Developer",
    company: "Wise",
    start: 2023,
    end: new Date().getFullYear().toString(),
    skills: {
      AngularJS: 20,
      HTML: 90,
      CSS: 90,
      JavaScript: 90,
      TypeScript: 80,
      React: 90,
      GraphQL: 70,
      NodeJS: 50,
      DesignSystems: 80,
      Vue: 40,
      Wordpress: 40,
    },
    details:
      "Working with Remix.js, React, Node, TypeScript, and GraphQL to build a new platform for Wise. Mentoring frontend engineers and working closely with the design team to create a new design system.",
  },
];

const database = {
  information: {
    name: "Nathan Hensher",
    aboutContent: `I'm a JavaScript engineer specialising in frontend development. With over ${getExperienceTime()} years experiance and extensive skills in Vue, React, TypeScript, and Node, I have developed a passion for creating innovative and user-friendly applications that make a difference in people's lives.`,
    personalContent:
      "Technology has always been a significant part of my life, both in and out of the workplace. I have a particular interest in computer hardware, which leads me to enjoy tinkering with electronics in my spare time. This hands-on approach helps me stay informed about new developments in the field and continually expand my knowledge and skills. While I find great satisfaction in my professional pursuits, my family is the cornerstone of my life. As a father to three children and to our whippet, Ralph, I've found that my personal life greatly influences my professional goals. My family provides the motivation that drives me to grow and improve in all aspects of life. This combination of technical curiosity and strong family ties shapes how I approach both work and personal challenges. It encourages me to think creatively, work diligently, and always keep in mind the bigger picture - not just for my career, but for the future I'm building with my family.",
    age: getAge("1991-03-10"),
    phone: "+447833592682",
    nationality: "British",
    language: "",
    email: "me@nathanhensher.dev",
    address: "Leamington Spa, UK",
    freelanceStatus: "Available",
    socialLinks: {
      facebook: "",
      // twitter: "https://twitter.com/nhensh",
      pinterest: "",
      behance: "",
      linkedin: "https://www.linkedin.com/in/nathan-hensher-43267956/",
      dribbble: "",
      github: "https://github.com/nhensh",
    },
    brandImage: "/images/brand-image.jpg",
    aboutImage: "/images/about-image.jpg",
    aboutImageLg: "/images/about-image-lg.jpg",
    cvfile: "/files/Nathan-Hensher-CV.pdf",
    cvLink:
      "https://docs.google.com/document/d/1I83lt_GOwx28ygiOCHQlnD2hekA-B2m-QrganFBrM58/edit?usp=sharing",
  },
  interests: [
    {
      title: "Electronics",
      icon: "react-lineicons",
      details:
        "Passionate about Raspberry Pis and microcontrollers, exploring their capabilities and applications.",
    },
    {
      title: "Football",
      icon: "contest",
      details:
        "Avid supporter of Liverpool FC and enjoy playing 5-a-side football regularly.",
    },
    {
      title: "Technology",
      icon: "lni-ux",
      details:
        "Keen interest in the latest TVs and PC components, staying up-to-date with cutting-edge tech.",
    },
    {
      title: "Gaming",
      icon: "lni-game",
      details:
        "Enthusiast gamer, enjoying various video game platforms and titles.",
    },
    {
      title: "Social",
      icon: "beer",
      details:
        "Enjoy unwinding with a beer at the pub, combining socializing with relaxation.",
    },
  ],
  reviews: [
    {
      id: 1,
      content:
        "I'm pleased to recommend my colleague, Nathan, for his outstanding work as a Lead Frontend Engineer. Having worked alongside him for my entire tenure at ManyPets, I can confidently vouch for his skills and qualities. Nathan is detail-oriented, ensuring our projects are always polished and up to high standards. He's hardworking and motivated, which keeps our team on track and inspired to push forward. His problem-solving abilities are impressive, allowing him to tackle complex challenges and stay updated on industry trends. As a personable team player, Nathan contributes positively to our work environment, making collaboration enjoyable and efficient. In summary, Nathan is a talented Lead Frontend Engineer who consistently delivers quality work and fosters a productive team atmosphere.",
      author: {
        name: "Sam Roberts",
        designation: "Frontend Engineer at ManyPets",
      },
    },
    {
      id: 2,
      content:
        "I worked with Nathan for over a year, he was the first point of contact when I joined as a contractor and got me set up and onboarded quickly. He continued to be a great team member when I joined full time. He’s got great knowledge of web fundamentals and produces simple solutions to complex problems. He’d be a great asset to any team he joins.",
      author: {
        name: "Ollie Baker",
        designation: "Senior Frontend Developer",
      },
    },
    {
      id: 3,
      content:
        "I have had the pleasure of working with Nathan for the last 3 years; he has been be a strong contributor within the squads we have collaborated together in and played a vital role in delivering critical initiatives. I have always found him to be a very dependable member of the team and someone that has a great deal of technical ability that I have countlessly relied on get projects over the line. I would take great comfort in Nathan being involved in any project I'm working on in the future and hope to get the opportunity to work with him again.",
      author: {
        name: "Steven Roberts",
        designation: "Technical Business Analyst at ManyPets",
      },
    },
  ],
  skills: [
    {
      title: "HTML",
      value: 95,
    },
    {
      title: "CSS",
      value: 90,
    },
    {
      title: "React",
      value: 90,
    },
    {
      title: "Javascript",
      value: 85,
    },
    {
      title: "Design Systems",
      value: 85,
    },
    {
      title: "Vue",
      value: 85,
    },
    {
      title: "TypeScript",
      value: 75,
    },
    {
      title: "NodeJS",
      value: 70,
    },
    {
      title: "SEO",
      value: 70,
    },
    {
      title: "WordPress",
      value: 65,
    },
    {
      title: "GraphQL",
      value: 60,
    },
    {
      title: "D3.js",
      value: 30,
    },
    {
      title: "Go",
      value: 20,
    },
  ],
  portfolio: [
    {
      id: 1,
      title: "ManyPets",
      subtitle: "A Nuxt.JS website for a pet insurance company.",
      imageUrl: "/images/ManyPets.jpeg",
      largeImageUrl: ["/images/ManyPets.jpeg"],
      url: "https://manypets.com",
    },
    {
      id: 2,
      title: "ManyGroup",
      subtitle: "A Nuxt.JS website for an insurance group.",
      imageUrl: "/images/ManyGroup.jpeg",
      url: "https://many-group.com",
    },
    {
      id: 3,
      title: "Factourism",
      subtitle: "A Wordpress website for facts, ",
      imageUrl: "/images/Factourism.gif",
      largeImageUrl: ["/images/Factourism.jpg"],
      url: "https://factourism.com",
    },
    {
      id: 4,
      title: "Bravissimo",
      subtitle:
        "A Next.JS website for a lingerie company with a custom ecommerce system.",
      imageUrl: "/images/Bravissimo.jpeg",
      url: "https://bravissimo.com",
    },
    {
      id: 5,
      title: "Profile Pensions",
      subtitle: "A Next.JS website for a pension advice fintech company",
      imageUrl: "/images/ProfilePensions.jpeg",
      largeImageUrl: ["/images/ProfilePensions.jpeg"],
      url: "https://profilepensions.co.uk",
    },
    {
      id: 6,
      title: "Data Viz Project",
      subtitle: "A data visualisation project using D3.js.",
      imageUrl: "/images/DataVizProject.gif",
      largeImageUrl: ["/images/DataVizProject.png"],
      url: "https://datavizproject.com",
    },
    {
      id: 7,
      title: "Metia",
      subtitle: "A .NET website for a marketing agency.",
      imageUrl: "/images/Metia.jpg",
      url: "https://metia.com/contact/?location=London#locations",
    },
  ],
  experience: {
    workExperience,
    educationExperience: [
      {
        id: 1,
        year: "2010 - 2013",
        graduation: "Bachelor of Science - 2:1",
        university: "University of Worcester (BSc Hons)",
        details: "Computer Science with Business Management",
      },
      {
        id: 2,
        year: "2010 - 2013",
        graduation: "Foundatin Degree of Science - Distinction",
        university: "Bournemouth University",
        details: "Computing",
      },
      {
        id: 3,
        year: "2007 - 2009",
        graduation: "Information Technologies - A Level",
        university: "Stratford Upon Avon 6th From",
        details: "",
      },
      {
        id: 4,
        year: "2007 - 2009",
        graduation: "Communication Studies - A Level",
        university: "Stratford Upon Avon College",
        details: "",
      },
      {
        id: 5,
        year: "2007 - 2009",
        graduation: "Fine Art - A Level",
        university: "Stratford Upon Avon 6th From",
        details: "",
      },
    ],
  },
  blog: [
    {
      id: 0,
      title: "The Rise of Asynchronous Work: Without Becoming a Hermit",
      description:
        "The rise of remote work has been a hot topic in recent years, with many companies adopting asynchronous work practices. But what does this mean for you? In this blog post, we explore the benefits and challenges of asynchronous work and how you can make it work for you.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/rise-of-async-work.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2021",
      publishedAt: "2021-02-20",
      updatedAt: "2021-02-20",
    },
    {
      id: 1,
      title:
        "The Final Nail in Lodash's Coffin: JavaScript's New Array Grouping",
      description:
        "Lodash has been a staple in JavaScript development for years, but with the introduction of new array grouping methods in ES6, is it time to say goodbye? In this blog post, we explore the benefits of using native JavaScript methods over Lodash and how you can make the switch.",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/final-nail-in-lodash-coffin.md",
      createDay: "1",
      createMonth: "July",
      createYear: "2024",
      publishedAt: "2024-07-01",
      updatedAt: "2024-10-01",
    },
  ],
  showcase: [
    {
      id: "ColourPaletteGenerator",
      title: "Colour Palette Generator",
      description: "A simple colour palette generator.",
      tags: ["React", "JavaScript", "CSS"],
      featuredImage: "/images/ColourPaletteGenerator.gif",
      createDay: "1",
      createMonth: "March",
      createYear: "2021",
      publishedAt: "2021-03-01",
      updatedAt: "2021-03-01",
    },
    {
      id: "ThemeToggle",
      title: "Light/Dark Toggle",
      description: "A simple light/dark mode toggle switch.",
      tags: ["React", "JavaScript", "CSS"],
      featuredImage: "/images/ThemeToggle.gif",
      createDay: "18",
      createMonth: "October",
      createYear: "2022",
      publishedAt: "2022-10-18",
      updatedAt: "2022-10-18",
    },
    {
      id: "ShowcaseWorkHistoryTimeline",
      title: "Work History Timeline",
      description: "A timeline of my work history.",
      tags: ["React", "JavaScript", "CSS", "D3.js"],
      featuredImage: "/images/WorkHistoryTimeline.jpg",
      createDay: "10",
      createMonth: "August",
      createYear: "2023",
      publishedAt: "2023-08-10",
      updatedAt: "2024-10-30",
    },
    {
      id: "ShowcaseSkeletonLoader",
      title: "Skeleton Loader",
      description: "A simple skeleton loader component.",
      tags: ["React", "JavaScript", "CSS"],
      featuredImage: "/images/SkeletonLoader.gif",
      createDay: "15",
      createMonth: "August",
      createYear: "2023",
      publishedAt: "2023-08-15",
      updatedAt: "2023-08-15",
    },
    {
      id: "BandedPaymentSlider",
      title: "Banded Range Slider",
      description: "A range slider with banded values.",
      tags: ["React", "JavaScript", "CSS"],
      featuredImage: "/images/BandedPaymentSlider.gif",
      createDay: "12",
      createMonth: "September",
      createYear: "2024",
      publishedAt: "2024-09-12",
      updatedAt: "2024-09-12",
    },
    // {
    //   id: "KochSnowflake",
    //   title: "Koch Snowflake",
    //   description: "A simple Koch snowflake animation using D3.js.",
    //   tags: ["React", "JavaScript", "CSS", "D3.js"],
    //   featuredImage: "/images/KochSnowflake.gif",
    //   createDay: "04",
    //   createMonth: "November",
    //   createYear: "2024",
    //   publishedAt: "2024-11-04",
    // },
    // {
    //   id: "LondonSkyline",
    //   title: "London Skyline",
    //   description: "A simple London skyline animation.",
    //   tags: ["React", "JavaScript", "CSS"],
    //   featuredImage: "/images/LondonSkyline.gif",
    //   createDay: "12",
    //   createMonth: "September",
    //   createYear: "2023",
    //   publishedAt: "2023-09-12",
    //   updatedAt: "2023-09-12",
    // },
  ],
  contactInfo: {
    phoneNumbers: ["+447833592682"],
    emailAddress: ["me@nathanhensher.dev"],
    address: "Leamington Spa, UK",
  },
};

Mock.onGet("/api/information").reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/interests").reply((config) => {
  const response = database.interests;
  return [200, response];
});

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolio").reply((config) => {
  const response = database.portfolio;
  return [200, response];
});

Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply((config) => {
  const response = database.blog;
  return [200, response];
});

Mock.onGet(/\/api\/blog\/(\d+)/).reply((config) => {
  const id = parseInt(config.url.split("/").pop());
  const post = database.blog.find((item) => item.id === id);
  return post ? [200, post] : [404, { message: "Blog post not found" }];
});

Mock.onGet("/api/showcase").reply((config) => {
  const response = database.showcase;
  return [200, response];
});

Mock.onGet(/\/api\/showcase\/(.+)/).reply((config) => {
  const id = config.url.split("/").pop();
  const showcase = database.showcase.find((item) => item.id === id);
  return showcase ? [200, showcase] : [404, { message: "Showcase not found" }];
});

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});

export default database;
