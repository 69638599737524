import axios from "axios";
import Disqus from "disqus-react";
import Markdown from "markdown-to-jsx";
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import Spinner from "../components/Spinner";
import { helmetJsonLdProp } from "react-schemaorg";

function BlogDetails(props) {
  const params = useParams();
  const [content, setContent] = useState("");
  const [metaData, setMetaData] = useState({});
  const blogId = parseInt(params.id);
  const blogFile = params.title;
  const fileName = `${blogFile}.md`;

  useEffect(() => {
    // Fetch blog metadata
    axios.get(`/api/blog/${blogId}`).then((response) => {
      setMetaData(response.data);
    });

    // Fetch blog content
    import(`../blog/${fileName}`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setContent(res))
          .catch((err) => console.error("Error fetching blog content:", err));
      })
      .catch((err) => console.error("Error importing blog file:", err));
  }, [blogId, fileName]);

  if (!metaData.title) {
    return <Spinner />;
  }

  // meta title should be 30 - 60 characters
  const metaTitle = metaData.title.length > 60 ? `${metaData.title.substring(0, 60)}...` : metaData.title;

  return (
    <Layout>
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@type": "Article",
            headline: metaData.title,
            image: metaData.featuredImage,
            datePublished: metaData.publishedAt,
            dateModified: metaData.updatedAt,
          })
        ]}
      >
        <title>Nathan Hensher - Blog - {metaData.title}</title>
        <meta
          name="description"
          content={`Nathan Hensher - ${metaData.description}`}
        />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta property="og:image" content={metaData.featuredImage} />
        <meta property="og:url" content={`https://nathanhensher.dev/blog/${metaData.id}`} />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content={metaData.description} />
        <meta name="twitter:image" content={metaData.featuredImage} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@nhensh" />
        <meta name="twitter:site" content="@nhensh" />
        <meta name="og:type" content="article" />
        <meta name="og:site_name" content="Nathan Hensher" />
        <meta name="article:author" content="Nathan Hensher" />
        <meta name="article:section" content="Blog" />
        <meta name="article:tag" content="Javascript, React, Node, Software Development" />
        <meta name="article:published_time" content={metaData.publishedAt} />
        <meta name="article:modified_time" content={metaData.updatedAt} />
        <meta name="revised" content={metaData.updatedAt} />
      </Helmet>
      <Suspense fallback={<Spinner />}>
        <div className="mi-blog-details mi-section mi-padding-top mi-padding-bottom">
          <div className="container">
            <p>Published on: {`${metaData.createDay} ${metaData.createMonth} ${metaData.createYear}`}</p>
            <Markdown>{content}</Markdown>
          </div>
        </div>
      </Suspense>
    </Layout>
  );
}

export default BlogDetails;