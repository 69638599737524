import React, { useState, useEffect } from "react";
import axios from "axios";
import LineIcon from "react-lineicons";

function Socialicons({ bordered }) {
  const [socialLinks, setSocialLinks] = useState({});
  const [error, setError] = useState(false);

  useEffect(() => {
    axios
      .get("/api/information")
      .then((response) => {
        setSocialLinks(response.data.socialLinks || {});
      })
      .catch(() => setError(true)); // Error handling for failed API requests
  }, []);

  // Map over social links for scalability and cleaner code
  const socialPlatforms = [
    { name: "facebook", label: "Facebook", iconName: "facebook" },
    { name: "twitter", label: "Twitter", iconName: "twitter" },
    { name: "pinterest", label: "Pinterest", iconName: "pinterest" },
    { name: "behance", label: "Behance", iconName: "behance" },
    { name: "linkedin", label: "LinkedIn", iconName: "linkedin" },
    { name: "dribbble", label: "Dribbble", iconName: "dribbble" },
    { name: "github", label: "GitHub", iconName: "github" },
  ];

  return (
    <nav aria-label="Social media links">
      {error && <p role="alert">Failed to load social links.</p>}
      <ul
        className={
          bordered ? "mi-socialicons mi-socialicons-bordered" : "mi-socialicons"
        }
      >
        {socialPlatforms.map((platform) => {
          const link = socialLinks[platform.name];
          return (
            link && (
              <li key={platform.name}>
                <a
                  href={link}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={`Visit my ${platform.label} page`}
                  title={`My ${platform.label}`}
                >
                  <LineIcon name={platform.iconName} aria-hidden="true" />
                </a>
              </li>
            )
          );
        })}
      </ul>
    </nav>
  );
}

export default Socialicons;
