import React, { useState, useEffect } from "react";

const CookieConsent = () => {
  const [consent, setConsent] = useState(null);

  useEffect(() => {
    const savedConsent = localStorage.getItem("cookieConsent");
    if (savedConsent) {
      setConsent(savedConsent === "true");
    }
  }, []);

  useEffect(() => {
    if (consent === true) {
      // Load Google Analytics script
      const script = document.createElement("script");
      script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`;
      script.async = true;
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    } else if (consent === false) {
      // Remove Google Analytics cookies
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
      });
    }
  }, [consent]);

  const handleAccept = () => {
    setConsent(true);
    localStorage.setItem("cookieConsent", "true");
  };

  const handleDecline = () => {
    setConsent(false);
    localStorage.setItem("cookieConsent", "false");
    document.cookie = "_ga=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
    document.cookie = "_gat=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
    document.cookie = "_gid=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
  };

  if (consent !== null) {
    return null;
  }

  return (
    <div className="cookie-consent">
      <div className="cookie-consent__message">
        <span>
          Sorry to interrupt, but just wanted to let you know about the cookies
          here.
        </span>
        <span>They're just used help me showcase my work. Do you accept?</span>
      </div>
      <div className="cookie-consent__buttons ">
        <button
          className="mi-button mi-button--secondary"
          onClick={handleDecline}
        >
          Decline
        </button>
        <button className="mi-button mi-button--primary" onClick={handleAccept}>
          Accept
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
