import React from "react";
import Showcase from "./Showcase";

function ShowcaseView({ components }) {
  return (
    <div className="row mt-30-reverse">
      {components.map((component) => (
        <div className="col-lg-6 col-md-6 col-12 mt-30" key={component.id}>
          <Showcase data={component} />
        </div>
      ))}
    </div>
  );
}

export default ShowcaseView;
