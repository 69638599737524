import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import Image from "./Image";

function Showcase(props) {
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  const { id, featuredImage, title, createDay, createMonth, createYear, tags } =
    props.data;
  const getShortMonth = (month) => month.slice(0, 3);

  const el = useRef(null);

  const mousePosition = (e) => {
    const x = e.clientX / window.innerWidth;
    const y = e.clientY / window.innerHeight;
    setMouseX(x * 100);
    setMouseY(y * 100);
  };

  const style = {
    content: "",
    position: "absolute",
    inset: 0,
    backgroundColor: `radial-gradient(200px circle at ${mouseX}% ${mouseY}%, #fff, #000)`,
    pointerEvents: "none",
  };

  return (
    <div className="mi-blog" ref={el} onMouseMove={mousePosition}>
      <div className="bg-animation" style={style} />
      <div className="mi-blog-image">
        <Link to={`${id}`}>
          {featuredImage && (
            <Image src={featuredImage} alt={title} height="350" width="350" />
          )}
        </Link>
        <div className="mi-blog-date">
          <span className="date">{createDay}</span>
          <span className="month">{getShortMonth(createMonth)}</span>
          <span className="year">{createYear}</span>
        </div>
      </div>
      <div className="mi-blog-content">
        <h5>
          <Link to={`${id}`}>{title}</Link>
        </h5>
        <span className="tags">Skills: {tags.map((tag) => `${tag}, `)}</span>
      </div>
    </div>
  );
}

export default Showcase;
