import React from "react";

const SkeletonLoader = ({
  width = "100%",
  height = "20px",
  margin = "10px 0",
  borderRadius = "4px",
  count = 1,
  circle = false,
}) => {
  const elements = [];

  for (let i = 0; i < count; i++) {
    elements.push(
      <div
        key={i}
        className={`skeleton-loader ${circle ? "circle" : ""}`}
        style={{
          width,
          height: circle ? width : height,
          margin,
          borderRadius: circle ? "50%" : borderRadius,
        }}
      >
        <div className="shimmer-wrapper">
          <div className="shimmer"></div>
        </div>
      </div>
    );
  }

  return <>{elements}</>;
};

const SkeletonArticle = () => {
  return (
    <div className="skeleton-article">
      <SkeletonLoader height="32px" width="50%" />
      <SkeletonLoader count={3} />
      <SkeletonLoader width="75%" />
    </div>
  );
};

const SkeletonProfile = () => {
  return (
    <div className="skeleton-profile">
      <div className="profile-header">
        <SkeletonLoader circle={true} width="80px" height="80px" />
        <div className="profile-info">
          <SkeletonLoader width="60%" />
          <SkeletonLoader width="40%" />
        </div>
      </div>
      <SkeletonLoader count={3} />
    </div>
  );
};

const ShowcaseSkeletonLoader = () => {
  return (
    <div className="showcase-skeleton-loader">
      <SkeletonArticle />
      <SkeletonProfile />
    </div>
  );
};

export default SkeletonLoader;

export { ShowcaseSkeletonLoader, SkeletonArticle, SkeletonProfile };
